const styles = {}

// colors
styles['color-white'] = '#FFFFFF'
styles['color-black'] = '#000000'
styles['color-dark'] = '#1C1B20'
styles['color-gray-light'] = '#B0B2BA'
styles['color-gray-lightest'] = '#F3F3F7'
styles['color-gray-text'] = '#FFFFFFCC'
styles['color-border-gray-light'] = '#E8E9F1'
styles['color-dark-gray'] = '#323232'
styles['color-darkest-gray'] = '#262626'
styles['color-pale-blue'] = '#f4f5ff'
styles['color-white-smoke'] = '#f5f5f5'

// texts
styles['text-color-dark'] = styles['color-dark']
styles['text-color'] = '#555'
styles['text-disabled-color'] = '#999'
styles['text-color-title'] = styles['text-color-dark']
styles['text-gray-light'] = '#4f4f4f'

// Home
styles['home-text-shadow'] = '#00000070'
styles['home-white-transparent'] = '#fffc'
styles['home-blue'] = '#004cdc'
styles['home-off-white'] = '#e3e3e3'
styles['home-black'] = '#000000'

// Project colors
styles['color-primary'] = '#0058FF'
styles['color-blue-accent'] = '#45F3D0'
styles['color-blue-light'] = '#59ABF9'
styles['color-blue-medium'] = '#3E75F7'
styles['color-accent'] = '#8533D7'
styles['color-error'] = '#ff4444'
styles['color-success'] = '#00c851'
styles['color-disabled'] = '#DDDDDD'
styles['color-light-accent'] = '#CFCFCF'
styles['color-dark-bg'] = '#231F20'
styles['color-light-bg'] = '#F2F2F2'
styles['color-light-border'] = '#E0E0E0'
styles['color-green-light'] = '#66E3DF'
styles['color-green-light-alt'] = '#00FFFF'
styles['color-green-lighter'] = '#00FFFF'

// Defaults
styles['default-font-family'] = "'DM Sans', sans-serif"
styles['default-title-font-family'] = "'Poppins', sans-serif"
styles['default-text-color'] = styles['text-color']
styles['default-input-border-color'] = '#9F9F9F'
styles['default-squared-font-family'] = "'Sora', sans-serif"

// Preserved variables placeholders
styles['root-hover-bg'] = styles['color-white']
styles['root-hover-text'] = styles['color-primary']

// Projects
// - Tapcart variables -
styles['tapcart-primary'] = '#363EA9'
styles['tapcart-primary-light'] = '#3943B5'
styles['tapcart-accent'] = '#45F3D0'

// - Lockitron variables -
styles['lockitron-primary'] = '#8BD4FF'
styles['lockitron-secondary'] = '#489ACA'
styles['lockitron-accent'] = '#434544'
styles['lockitron-gray-accent'] = '#7c8b9b'

// - Skyroam variables -
styles['skyroam-primary'] = '#F05325'
styles['skyroam-secondary'] = '#555759'
styles['skyroam-gray'] = '#F3F3F7'

// - FindHeli variables -
styles['findheli-blue-primary'] = '#091a51'
styles['findheli-blue-light'] = '#EBECF1'
styles['findheli-blue-secondary'] = '#07174a'
styles['findheli-blue-accent'] = '#083D83'
styles['findheli-yellow-primary'] = '#FEBB01'

// - VPP variables -
styles['vpp-blue-primary'] = '#0075B0'
styles['vpp-green-accent'] = '#7AB800'

// - SU variables -
styles['su-primary'] = '#091B2F'
styles['su-gray'] = '#152639'
styles['su-pink'] = '#E2228D'
styles['su-blue'] = '#2A65AF'
styles['su-orange'] = '#FF7900'
styles['su-dark'] = '#1F263D'

// - Camio variables -
styles['camio-primary'] = '#ED962C'
styles['camio-secondary'] = '#4FB535'

// - EQI variables -
styles['eqi-primary'] = '#064474'
styles['eqi-secondary'] = '#033044'
styles['eqi-accent'] = '#FEBE3B'
styles['eqi-accent-dark'] = '#775A1E'

// - ExMox variables -
styles['exmox-primary'] = '#5458B5'
styles['exmox-primary-dark'] = '#303030'
styles['exmox-secondary'] = '#DFDFDF'
styles['exmox-secondary-light'] = '#F4F4F4'

// - Woovit variables -
styles['woovit-blue-primary'] = '#2A4158'
styles['woovit-blue-secondary'] = '#0C5687'
styles['woovit-blue-accent'] = '#1176B9'
styles['woovit-yellow'] = '#F2A615'
styles['woovit-teal'] = '#0BD0B4'
styles['woovit-light'] = '#F7FBFE'
styles['woovit-gradient'] = '#141d27'

// - Meli variables -
styles['meli-primary'] = '#F7E000'
styles['meli-secondary'] = '#283483'

// - Swfast variables -
styles['swfast-primary'] = '#3A7C29'
styles['swfast-yellow'] = '#ffff1a'
styles['swfast-green-light'] = '#90D346'
styles['swfast-green-dark'] = '#35AE32'
styles['swfast-stripe'] = 'rgba(255, 255, 255, 0.05)'

// - Sodexo variables -
styles['sodexo-red-dark'] = '#882A2C'
styles['sodexo-red-light'] = '#E63623'
styles['sodexo-blue-dark'] = '#272F59'

// - QuiverQuantitative variables -
styles['quiver-light-greyish'] = '#1F242D'
styles['quiver-water-greenish'] = '#5DD6BA'
styles['quiver-dark-greyish'] = '#25303c'
styles['quiver-grey-text'] = '#4f4f4f'
styles['quiver-firstSection-light-bg'] = '#5dd6ba33'
styles['quiver-firstSection-dark-bg'] = '#5dd6ba00'
styles['quiver-dot-color'] = '#5ed7ba'
styles['quiver-dot-blue-color'] = '#5152f8'
styles['quiver-quote-color'] = '#6573f7'

// - Cresol variables -
styles['cresol-orange'] = '#F37533'
styles['cresol-gray'] = '#4F4F4F'
styles['cresol-green-dark'] = '#005744'

// - Lys Academy variables -
styles['lys-green'] = '#59C0A9'
styles['lys-blue-light'] = '#CDE8F7'
styles['lys-blue-medium'] = '#39A2DF'
styles['lys-blue-dark'] = '#3089C6'
styles['lys-pink'] = '#E63889'
styles['lys-red-light'] = '#eb6666'
styles['lys-text-dark'] = '#334a68'
styles['lys-text-dark-secondary'] = '#4f4f4f'
styles['lys-background'] = '#F3F3F7'
styles['lys-yellow'] = '#f7c239'

// - THAW variables -
styles['thaw-primary'] = '#F36D25'
styles['thaw-gray-dark'] = '#363230'
styles['thaw-gray-light'] = '#6F6562'
styles['thaw-background'] = '#F3F3F7'
styles['thaw-dark-text'] = '#301303'

// - Move variables -
styles['move-primary'] = '#0341B6'

// - Bluebenx variables -
styles['BB-blue-dark'] = '#001F4D'
styles['BB-blue'] = '#0047BA'
styles['BB-blue-light'] = '#0068FF'

styles['BB-dot-dark'] = '#001F4D'
styles['BB-dot-light'] = '#00D7FF'
styles['BB-dot-lighter'] = '#99C2FF'

// - Raimana variables -
styles['raimana-blue-light'] = '#F8FAFF'
styles['raimana-blue-medium'] = '#8BD4FF'
styles['raimana-blue-dark'] = '#115EFB'
styles['raimana-gray-dark'] = '#4f4f4f'
styles['raimana-green-light'] = '#6CE3DD'
styles['raimana-purple'] = '#843CD3'

// - Service Page variables -
styles['servicepage-brands-bg-dark'] = '#161616'

// - Template variables -
styles['template-primary'] = '#153466'
styles['template-secondary'] = '#2a263e'

// Blockchain service page
styles['blockchain-blue'] = styles['color-primary']
styles['blockchain-white'] = styles['color-white']
styles['blockchain-gray'] = '#4F4F4F'
styles['blockchain-light-gray'] = '#6F6F6F'
styles['blockchain-black'] = '#000000'
styles['blockchain-white'] = styles['color-white']
styles['blockchain-faq-bg'] = '#F5F6F9'

module.exports = styles
