import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const context = React.createContext({ isMobile: false, setIsMobile: () => {} })

const MOBILE_BREAK = 768
export const MobileViewProvider = ({ children }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  useEffect(() => {
    setIsMobile(window.innerWidth <= MOBILE_BREAK)
  }, [])
  return <context.Provider value={{ isMobile, setIsMobile }}>{children}</context.Provider>
}

const useMobileView = () => {
  const contextValue = React.useContext(context)
  if (!contextValue) {
    throw new Error('useFooterHover must be used within a FooterHoverProvider')
  }
  return contextValue
}

MobileViewProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

MobileViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
export default useMobileView
